import { render, staticRenderFns } from "./choosebox.vue?vue&type=template&id=7dc37cc7&scoped=true&"
import script from "./choosebox.vue?vue&type=script&lang=js&"
export * from "./choosebox.vue?vue&type=script&lang=js&"
import style0 from "./choosebox.vue?vue&type=style&index=0&id=7dc37cc7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dc37cc7",
  null
  
)

export default component.exports