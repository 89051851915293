<template>
  <div class="show-test">
    <el-row :gutter="15" v-if="con">
      <el-col :span="18">
        <el-row class="test-title">
          <h1>试卷名称:{{ test.name }}</h1>
        </el-row>
        <show-paper :one="con"></show-paper>
        <el-row type="flex" class="share">
          <div @click="click_back" :class="back===false ?'disabled':''">
            <i class="fa fa-arrow-left"></i>
            上一题
          </div>
          <div @click="add" class="add">
            <i class="fa fa-plus-square-o"></i>
            添加错题集
          </div>
          <div @click="click_next" :class="next===false ?'disabled':''">
            <i class="fa fa-arrow-right"></i>
            下一题
          </div>
        </el-row>
      </el-col>
      <el-col :span="6">
        <choosebox :id="paperId"
                   :completion="completion"
                   :judge="judge"
                   :choose="choose"
                   :short-answer="shortAnswer"
                   @choose="getChoose"></choosebox>
      </el-col>
    </el-row>
  </div>
</template>

<script>

// 这边是展示
import showPaper from "@/views/paper/common/show-paper";
import Choosebox from "@/views/paper/index/choosebox";


import {getTestById} from "@/api/test";
import {addWrong} from "@/api/wrong";

export default {
  name: "ShowTest",
  components: {
    Choosebox,
    showPaper
  },

  data() {
    return {
      con: "",
      //个人查看试卷，则为true ,查看别人的试卷则为false
      isShow: this.$route.query.isShow === '1',
      // 选择
      flag: '1',
      // 试卷的内容
      test: {},
      // 题目内容
      paper: [],
      // 试卷id
      id: this.$route.query.id,

      text: ["选择题", "判断题", "填空题", "简答题"],
      // 选择题
      choose: [],
      // 填空题
      completion: [],
      // 判断题
      judge: [],
      // 简答题
      shortAnswer: [],
      index: 0,
      next: true,
      back: false,
      paperId: ""
    }
  },
  mounted() {
    this.getTest();
  },
  methods: {
    // 添加错题集
    add() {
      let wrong = {
        userId: this.$store.state.user.id,
        paperId: this.con.id
      }
      addWrong(wrong).then(result => {
        this.$message({
          type: "success",
          message: result.data.wrong,
          duration: 2 * 1000
        });
      }).catch(error => {
        this.$message.error(error.message)
      })
    },
    click_next() {
      // index超过
      if (this.index >= this.paper.length - 1) {
        this.back = true;
        this.next = false;
        this.$message("已经是最后一个")
        return
      }
      this.back = true;
      this.index++;
    },
    click_back() {
      if (this.index <= 0) {
        this.back = false;
        this.next = true;
        this.$message("已经是第一个了")
        return
      }
      this.index--;
      this.next = true;
    },
    select(index) {
      console.log("点击了菜单" + index);
      this.flag = index;
    },
    getChoose(item) {
      this.con = item;
      this.index = this.paper.findIndex(function (currentValue, index, arr) {
        return currentValue.id === item.id
      })
    },
    // 获取试卷的所有信息
    getTest() {
      getTestById(this.id).then(result => {
        console.log(result)
        this.test = result.data.test.test;
        this.paper = result.data.test.paperList;
        // con默认表示为第一个题目
        this.con = this.paper[this.index];
        if (this.paper !== null) {
          this.paper.forEach(item => {
            if (item.paperType === 1) {
              this.choose.push(item)
            } else if (item.paperType === 3) {
              this.completion.push(item)
            } else if (item.paperType === 2) {
              this.judge.push(item)
            } else if (item.paperType === 4) {
              this.shortAnswer.push(item)
            }
          })
        }
      }).catch(error => {
        this.$message.error(error.message)
      })
    }
  },
  watch: {
    con() {
      this.paperId = this.con.id;
    },
    index() {
      this.con = this.paper[this.index];
      if (this.index <= 0) {
        this.back = false;
      }
      if (this.index >= this.paper.length - 1) {
        this.next = false;
      }
    }
  }
}
</script>

<style scoped>
.show-test {
  background-color: #fff;
  margin: 0 auto;
  min-height: calc(100vh - 60px);
  padding: 20px;
}


.test-title {
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 20px;
}

/*  最下面一个部分 */
.share div {
  width: 50%;
  text-align: center;
  line-height: 40px;
  height: 40px;
  border: 1px solid #f0f0f0;
}

.share div:hover {
  color: #0066ff;
}

/* 禁止点击事件*/
.disabled {
  color: #c0c4cc;

}
</style>
