<template>
  <div>
    <div class="faqbox" v-if="choose">
      <div class="title">
        选择题
      </div>
      <div class="content">
        <el-row>
          <el-col :span="4" class="box" :class="id===item.id?'box-red':'box-blue'" v-for="(item,index) in choose" :key="index" @click.native="getClick(item)">
            {{ index + 1 }}
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="faqbox" v-if="judge">
      <div class="title">
        判断题
      </div>
      <div class="content">
        <el-row>
          <el-col :span="4" class="box" v-for="(item,index) in judge" :class="id===item.id?'box-red':'box-blue'" :key="index" @click.native="getClick(item)">
            {{ index + 1 }}
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="faqbox" v-if="completion">
      <div class="title">
        填空题
      </div>
      <div class="content">
        <el-row>
          <el-col :span="4" class="box" :class="id===item.id?'box-red':'box-blue'" v-for="(item,index) in completion" :key="index" @click.native="getClick(item)">
            {{ index + 1 }}
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="faqbox" v-if="shortAnswer">
      <div class="title">
        简答题
      </div>
      <div class="content">
        <el-row>
          <el-col :span="4" class="box" :class="id===item.id?'box-red':'box-blue'" v-for="(item,index) in shortAnswer" :key="index" @click.native="getClick(item)">
            {{ index + 1 }}
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "choosebox",
  props: {
    id:String,
    number: {
      type: Array,
      default() {
        return []
      }
    },
    choose: {
      type: Array,
      default() {
        return []
      }
    },
    judge: {
      type: Array,
      default() {
        return []
      }
    },
    completion: {
      type: Array,
      default() {
        return []
      }
    },
    shortAnswer: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {}
  },
  methods: {
    getClick(item) {
      this.$emit("choose", item)
    }
  }
}
</script>

<style scoped>

.faqbox {
  width: 100%;
  overflow: hidden;
  background: #f9f9f9;
  border: 1px solid #e7e7e7;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  padding-left: 0;
  padding-right: 0;
  float: left;
  margin: 0 10px 5px;
}

/*  标题*/
.title {
  text-align: left;
  font-size: 20px;
  color: #000;
  font-weight: normal;
  padding: 17px 0 17px 17px;
  margin: 0 0 0 0;
  /*background: #fff url(src/assets/image/i6.png) no-repeat 25px center;*/
  border-bottom: 1px solid #e7e7e7;
}

.content {
  text-align: left;
  font-size: 14px;
  color: #5f5f5f;
  line-height: 24px;
  padding: 4px;
  margin: 0;
  width: 100%;
}
.box{
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  margin: 4px;
  text-align: center;
  border: 1px solid #dcdfe6;
  color: #ffffff;
}
.box-blue{
  background-color: #409eff;
}

.box-red{
  background-color: #f56c6c;
}
</style>
